.carousel-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  background: #319b4f;
  min-height: 55vh;
}

.carousel-body {
  display: flex;
}

.carousel-header {
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
  padding: 1rem 0 0 0;
  color: white;
}

.carousel-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carousel-image {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  max-width: 20rem;
  box-shadow: 10px 10px 5px green;
}

.carousel-copy {
  display: flex;
  flex-direction: column;
  color: white;
  font-weight: 400;
  padding: 1rem;
  max-width: 20rem;
  text-align: center;
  justify-content: center;
  line-height: 1.25rem;
}

.carousel-navigation {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (min-width: 768px) {
  .carousel-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .carousel-section {
    min-height: 40vh;
  }
}

.home-body {
  max-width: 85%;
}
.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.main {
  display: flex;
  /* height: 25vh; THIS messes up keyboard behaviour I think, not sure why*/
  flex-direction: column;
  justify-content: center;
  max-width: 40rem;
  padding: 1.5rem;
  min-height: 20vh;
}

.title {
  text-align: center;
  font-weight: bold;
  font-size: 3rem;
}

.description {
  font-size: 2.4rem;
  font-weight: bold;
  text-align: center;
}

section {
  display: flex;
  flex-direction: column;
  padding: 2rem 5rem;
  width: 100%;
  background-color: rgb(174, 197, 251);
}

.section-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-image {
  padding: 1rem 2rem;
}

.section-header {
  text-align: center;
  font-size: 2rem;
}

.section-copy {
  padding: 1rem 2rem;
}

.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid lightgray;
  color: white;
  font-weight: bold;
  background-color: #343434;
}

.feedback-link {
  color: white;
  padding: 0.5rem;
}

.about-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding: 0rem 0 2rem 0;
}

.about-body {
  display: flex;
}

.about-header {
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
  padding: 1rem 0 1rem 0;
  color: black;
}

.about-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-copy {
  display: inline;
  flex-direction: column;
  color: black;
  font-weight: 400;
  padding: 0rem 1.5rem 1rem 1.5rem;
  max-width: 35rem;
  text-align: center;
  justify-content: center;
  line-height: 1.25rem;
}

.linkedin-link {
  color: darkgreen;
}

@media only screen and (min-width: 768px) {
  .section-content {
    flex-direction: row;
  }
}

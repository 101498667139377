.presentation-container {
  display: flex;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.clickable {
  position: absolute;
  height: 50%;
  width: 4rem;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.card-content {
  width: 100%;
  padding: 4rem;
  max-height: 100vh;
  text-align: center;
  overflow-y: scroll;
  font-size: 1.5rem;
}

.bold {
  font-weight: bold;
}

.arrows {
  align-self: center;
  opacity: 0.35;
}

.arrows:hover {
  cursor: pointer;
}

.images {
  object-fit: contain;
}

.close-presentation {
  opacity: 0.35;
  padding: 1rem 1rem;
  position: absolute;
  right: 0;
  top: 0;
}

.close-presentation:hover {
  cursor: pointer;
}

.deck-end {
  margin: 0 2rem;
  font-weight: bold;
}

/*

@media only screen and (min-width: 768px) {
	.clickable:hover {
		opacity: 75%;
	}
}


.clickable:active {
	opacity: 75%;
}


*/

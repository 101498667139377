button {
  height: 2.5rem;
  color: white;
  border: none;
  padding: 0 1rem;
  margin: 0.5rem 1rem;
  font-weight: bolder;
  font-size: 1rem;
  min-width: 9rem;
}

button:hover {
  opacity: 0.75;
  cursor: pointer;
}

.red {
  background-color: #db4437;
}

.green {
  background-color: #35a853;
}

.blue {
  background-color: #4285f4;
}
